var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.block)?_c('div',{staticClass:"area"},[_c('div',{staticStyle:{"width":"100%","height":"45px","background-color":"white","position":"absolute"}}),_c('div',{staticClass:"layout"},[(
        _vm.block.Image &&
        _vm.block.Image.data &&
        _vm.block.Image.data.attributes &&
        _vm.block.Image.data.attributes.url
      )?_c('div',{staticClass:"pic",style:({
        'background-image':
          'url(' + _vm.strapiAssetUrl(_vm.block.Image.data.attributes.url) + ')',
      })}):_vm._e(),_c('div',{staticClass:"section"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('h1',{staticClass:"fisrt-txt"},[_c('span',{staticClass:"pink"},[_vm._v(_vm._s(_vm.block.Title))])]),(_vm.block.Subtitle)?_c('div',{staticClass:"title"},[_c('div',{staticClass:"txt"},[_vm._v(_vm._s(_vm.block.Subtitle))])]):_vm._e(),_c('calculator-form',{attrs:{"commissionPercentage":_vm.block.CommissionPercentage,"interestPercentage":_vm.block.InterestPercentage,"finalTermDefaultValue":_vm.block.FinalTermDefaultValue,"finalTermShow":_vm.block.FinalTermShow,"paymentPerMonth":_vm.block.PaymentPerMonth,"subTitle":_vm.block.Subtitle}}),_c('btn-big',{staticClass:"allGreen",attrs:{"width":370,"height":92,"click":_vm.goToRoute}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"txt"},[_vm._v("Financial lease aanvragen")]),_c('img',{attrs:{"src":require("@/assets/arrow.svg"),"alt":""}})])])],1)])])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"only-tablet logo-wrapper"},[_c('img',{attrs:{"src":require("../../../assets/logo.png")}})])}]

export { render, staticRenderFns }