
import { uiInterface, uiInterfaceDate, createProperty, validateEmail, validatePhone, rxValidEmailChars, rxLegalPhoneChars, validatePostCode, rxValidPostCodeChars } from './data.js';

function easeIn (val) {
  return val * val * val;
}

function easeOut (val) {
  return Math.cbrt(val);
}

function Form0() {
    return {
        // selectedFunnel: new Number(),
        loanOption: new Number(NaN),
        loanAmount: new Number(),
        monthlyFee: new Number(),
        moreLoans: new Boolean(),
        livingState: new Number(),
        monthlyExpenses: new Number(),
        income: new Number(),
        incomeSource: new Number(),
        dateService: new Date(NaN),
        firstName: new String(),
        middleName: new String(),
        lastName: new String(),
        gender: new String(),
        birthDate: new Date(NaN),
        civilState: new Boolean(),
        children: new Boolean(),
        streetName: new String(),
        houseNumber: new String(),
        affix: new String(),
        postCode: new String(),
        city: new String(),
        addressSince: new String(),
        emailAddress: new String(),
        fixedPhoneNumber: new String(),
        mobilePhoneNumber: new String(),
        comments: new String(),

        partnerFirstName: new String(),
        partnerMiddleName: new String(),
        partnerLastName: new String(),
        partnerGender: new String(),
        partnerBirthDate: new Date(NaN),
        partnerIncome: new Number(NaN),
        partnerIncomeSource: new Number(NaN),
        partnerDateService: new Date(NaN)
    };
}

var form1Data = {
    loanAmount: new Number(),
    agreementNumber: new String(),
    accountNumber: new String(),
    firstName: new String(),
    middleName: new String(),
    lastName: new String(),
    gender: new String(),
    birthDate: new Date(NaN),
    streetName: new String(),
    houseNumber: new String(),
    affix: new String(),
    postCode: new String(),
    city: new String(),
    emailAddress: new String(),
    fixedPhoneNumber: new String(),
    mobilePhoneNumber: new String(),
    comments: new String()
};

var form1DataTest = {
    loanAmount: new Number(25000),
    agreementNumber: new String("Agreement number test"),
    accountNumber: new String("NL0192830129381"),
    firstName: new String("John"),
    middleName: new String("M."),
    lastName: new String("Doe"),
    gender: new String("male"),
    birthDate: new Date("2019-06-11T17:47:58.259Z"),
    streetName: new String("Street Name"),
    houseNumber: new String("15A"),
    affix: new String("Affix"),
    postCode: new String("NL1234"),
    city: new String("Rotterdam"),
    emailAddress: new String("testemail@domain.nl"),
    fixedPhoneNumber: new String("06000000000"),
    mobilePhoneNumber: new String("06000000000"),
    comments: new String("Some comments here")
};

function Form1() {
    return form1Data; //Test;
}

function Form2() {
    return {
        firstName: new String(),
        middleName: new String(),
        lastName: new String(),
        gender: new String(),
        birthDate: new Date(NaN),
        civilState: new Boolean(),
        streetName: new String(),
        houseNumber: new String(),
        affix: new String(),
        postCode: new String(),
        city: new String(),
        streetNameChange: new String(),
        houseNumberChange: new String(),
        affixChange: new String(),
        postCodeChange: new String(),
        cityChange: new String(),
        addressChangeDate: new Date(NaN),
        accountNumber: new String(),
        accountNumberChangeDate: new Date(NaN),
        emailAddress: new String(),
        fixedPhoneNumber: new String(),
        mobilePhoneNumber: new String(),
        contactsChangeDate: new Date(NaN),
        comments: new String(),

        partnerFirstName: new String(),
        partnerMiddleName: new String(),
        partnerLastName: new String(),
        partnerGender: new String(),
        partnerBirthDate: new Date(NaN),
        partnerIncome: new Number(NaN),
        partnerIncomeSource: new Number(NaN),
        partnerDateService: new Date(NaN)
    };
}

function smallForm() {
    return {
        name: new String(),
        phoneNumber: new String(),
        emailAddress: new String()
    };
}

function _form0() {
    var self = this;
    var source = Form0();

    function requiredIfCivilState() {
        return source.civilState == true ? true : false;
    }

    // createProperty(source, self, 'selectedFunnel', new uiInterface({ required: true }));
    createProperty(source, self, 'loanOption', new uiInterface({ required: true, label: 'Wat is uw bestedingsdoel?', order: 0, placeholder: 'Maak uw keuze' }));
    createProperty(source, self, 'loanAmount', new uiInterface({ required: true, label: 'Hoeveel geld wilt u lenen?', order: 1, minLen: 4, maxLen: 6, minVal: 2500, maxVal: 100000, step: 100, unit: "€", placeholder: "€2.500", eIn: easeIn, eOut: easeOut }));
    createProperty(source, self, 'monthlyFee', new uiInterface({ required: true, label: 'Hoeveel wenst u per maand af te lossen?', order: 2, minLen: 4, maxLen: 6, minVal: 2500, maxVal: 100000, step: 1, unit: "€", placeholder: "€2.500", eIn: easeIn, eOut: easeOut }));
    createProperty(source, self, 'moreLoans', new uiInterface({ required: true, label: 'Heeft u andere leningen lopen?', order: 3 }));
    ////////////////////////////////////////////////////////////////////
    createProperty(source, self, 'livingState', new uiInterface({ required: true, label: 'Wat is uw woonsituatie?', order: 4, placeholder: "Koophuis" }));
    createProperty(source, self, 'monthlyExpenses', new uiInterface({ required: true, label: 'Wat zijn uw maandlasten?', order: 5, minLen: 3, maxLen: 5, minVal: 200, maxVal: 2500, step: 1, unit: "€", placeholder: "€200", eIn: easeIn, eOut: easeOut }));
    createProperty(source, self, 'income', new uiInterface({ required: true, label: 'Netto inkomen per maand?', order: 6, minLen: 3, maxLen: 5, minVal: 500, maxVal: 5000, step: 1, unit: "€", placeholder: "€500", eIn: easeIn, eOut: easeOut }));
    createProperty(source, self, 'incomeSource', new uiInterface({ required: true, label: 'Wat is uw inkomstenbron?', order: 7, placeholder: 'Vast dienstverband' }));
    createProperty(source, self, 'dateService', new uiInterfaceDate({ required: true, label: 'Datum in dienst', order: 8 }));
    ////////////////////////////////////////////////////////////////////
    createProperty(source, self, 'firstName', new uiInterface({ required: true, label: 'Voorletter', order: 9 }));
    createProperty(source, self, 'middleName', new uiInterface({ required: false, label: 'Tussenvoegsel', order: 10 }));
    createProperty(source, self, 'lastName', new uiInterface({ required: true, label: 'Achternaam', order: 11 }));
    createProperty(source, self, 'gender', new uiInterface({ required: true, name: 'gender0', label: 'Geslacht', order: 12, optionA: 'male', optionB: 'female' }));
    createProperty(source, self, 'birthDate', new uiInterfaceDate({ required: true, label: 'Geboortedatum', order: 13 }));
    createProperty(source, self, 'civilState', new uiInterface({ required: true, label: 'Burgerlijke staat', order: 14 }));
    createProperty(source, self, 'children', new uiInterface({ required: true, label: 'Heeft u inwonende kinderen?', order: 15 }));
    createProperty(source, self, 'streetName', new uiInterface({ required: true, label: 'Straat', order: 16 }));
    createProperty(source, self, 'houseNumber', new uiInterface({ required: true, label: 'Huisnummer', order: 17 }));
    createProperty(source, self, 'affix', new uiInterface({ required: false, label: 'Toevoeging', order: 18 }));
    createProperty(source, self, 'postCode', new uiInterface({ required: true, label: 'Postcode', validator: validatePostCode, rxValidChars: rxValidPostCodeChars, minLen: 6, maxLen: 7, order: 19 }));
    createProperty(source, self, 'city', new uiInterface({ required: true, label: 'Plaats', order: 20 }));
    createProperty(source, self, 'addressSince', new uiInterface({ required: true, name: 'addressSince0', label: 'Sinds wanneer woont u hier?', order: 21 }));

    ////////////////////////////////////////////////////////////////////
    createProperty(source, self, 'partnerFirstName', new uiInterface({ required: requiredIfCivilState, label: 'Partner voorletter', order: 22 }));
    createProperty(source, self, 'partnerMiddleName', new uiInterface({ required: false, label: 'Partner tussenvoegsel', order: 23 }));
    createProperty(source, self, 'partnerLastName', new uiInterface({ required: requiredIfCivilState, label: 'Partner achternaam', order: 24 }));
    createProperty(source, self, 'partnerGender', new uiInterface({ required: requiredIfCivilState, name: 'partnerGender0', label: 'Partner geslacht', order: 25, optionA: 'male', optionB: 'female' }));
    createProperty(source, self, 'partnerBirthDate', new uiInterfaceDate({ required: requiredIfCivilState, label: 'Partner geboortedatum', order: 26 }));
    createProperty(source, self, 'partnerIncome', new uiInterface({ required: requiredIfCivilState, label: 'Partner netto inkomen per maand?', order: 27, minLen: 1, maxLen: 5, minVal: 1, maxVal: 5000, step: 1, unit: "€", placeholder: "€500", eIn: easeIn, eOut: easeOut }));
    createProperty(source, self, 'partnerIncomeSource', new uiInterface({ required: requiredIfCivilState, label: 'Partner wat is uw inkomstenbron?', order: 28, placeholder: 'Vast dienstverband' }));
    createProperty(source, self, 'partnerDateService', new uiInterfaceDate({ required: requiredIfCivilState, label: 'Partner datum in dienst', order: 29 }));

    ////////////////////////////////////////////////////////////////////
    createProperty(source, self, 'emailAddress', new uiInterface({ required: true, label: 'E-mail', order: 30, validator: validateEmail, rxValidChars: rxValidEmailChars }));
    createProperty(source, self, 'fixedPhoneNumber', new uiInterface({ validator: validatePhone, label: 'Vaste telefoonnummer', rxValidChars: rxLegalPhoneChars, minLen: 10, maxLen: 10, order: 31 }));
    createProperty(source, self, 'mobilePhoneNumber', new uiInterface({ required: true, label: 'Mobiele telefoonnummer', order: 32, validator: validatePhone, rxValidChars: rxLegalPhoneChars, minLen: 10, maxLen: 10 }));
    createProperty(source, self, 'comments', new uiInterface({ required: false, label: 'Opmerkingen', order: 33 }));

    return self;
}

function _form1() {
    var self = this;
    var source = Form1();
    createProperty(source, self, 'loanAmount', new uiInterface({ required: true, label: 'Leenbedrag', order: 0, minLen: 4, maxLen: 6, minVal: 2500, maxVal: 100000, step: 100, unit: "€", placeholder: "€2.500", eIn: easeIn, eOut: easeOut }));
    createProperty(source, self, 'agreementNumber', new uiInterface({ required: true, label: 'Overeenkomstnummer', order: 1 }));
    createProperty(source, self, 'accountNumber', new uiInterface({ required: true, label: 'bankrekeningnummer', order: 2 }));
    ////////////////////////////////////////////////////////////////////
    createProperty(source, self, 'firstName', new uiInterface({ required: true, label: 'Voorletter', order: 3 }));
    createProperty(source, self, 'middleName', new uiInterface({ required: false, label: 'Tussenvoegsel', order: 4 }));
    createProperty(source, self, 'lastName', new uiInterface({ required: true, label: 'Achternaam', order: 5 }));
    createProperty(source, self, 'gender', new uiInterface({ required: true, name: 'gender1', label: 'Geslacht', optionA: 'male', optionB: 'female', order: 6 }));
    createProperty(source, self, 'birthDate', new uiInterfaceDate({ required: true, label: 'Geboortedatum', order: 7 }));
    createProperty(source, self, 'streetName', new uiInterface({ required: true, label: 'Straat', order: 8 }));
    createProperty(source, self, 'houseNumber', new uiInterface({ required: true, label: 'Huisnummer', order: 9 }));
    createProperty(source, self, 'affix', new uiInterface({ required: false, label: 'Toevoeging', order: 10 }));
    createProperty(source, self, 'postCode', new uiInterface({ required: true, label: 'Postcode', validator: validatePostCode, rxValidChars: rxValidPostCodeChars, minLen: 6, maxLen: 7, order: 11 }));
    createProperty(source, self, 'city', new uiInterface({ required: true, label: 'Plaats', order: 12 }));
    ////////////////////////////////////////////////////////////////////
    createProperty(source, self, 'emailAddress', new uiInterface({ required: true, label: 'E-mail', order: 13, validator: validateEmail, rxValidChars: rxValidEmailChars }));
    createProperty(source, self, 'fixedPhoneNumber', new uiInterface({ required: false, label: 'Vaste telefoonnummer', validator: validatePhone, rxValidChars: rxLegalPhoneChars, minLen: 10, maxLen: 10, order: 14 }));
    createProperty(source, self, 'mobilePhoneNumber', new uiInterface({ required: true, label: 'Mobiele telefoonnummer', validator: validatePhone, rxValidChars: rxLegalPhoneChars, minLen: 10, maxLen: 10, order: 15 }));
    createProperty(source, self, 'comments', new uiInterface({ required: false, label: 'Opmerkingen', order: 16 }));

    // for(let p in self) {
    //     if(self[p] && self[p].ui) {
    //         self[p].ui.init = true;
    //         self[p].ui.valid = true;
    //     }
    // }

    return self;
}

function _form2() {
    var self = this;
    var source = Form2();

    function requiredIfCivilState() {
        return source.civilState ? true : false;
    }

    createProperty(source, self, 'firstName', new uiInterface({ required: true, label: 'Voorletter', order: 0 }));
    createProperty(source, self, 'middleName', new uiInterface({ required: false, label: 'Tussenvoegsel', order: 1 }));
    createProperty(source, self, 'lastName', new uiInterface({ required: true, label: 'Achternaam', order: 2 }));
    createProperty(source, self, 'gender', new uiInterface({ required: true, name: 'gender2', optionA: 'male', optionB: 'female', label: 'Geslacht', order: 3 }));
    createProperty(source, self, 'birthDate', new uiInterfaceDate({ required: true, label: 'Geboortedatum', order: 4 }));
    createProperty(source, self, 'civilState', new uiInterface({ required: true, label: 'Burgerlijke staat', order: 5 }));
    createProperty(source, self, 'streetName', new uiInterface({ required: true, label: 'Straat', order: 6 }));
    createProperty(source, self, 'houseNumber', new uiInterface({ required: true, label: 'Huisnummer', order: 7 }));
    createProperty(source, self, 'affix', new uiInterface({ required: false, label: 'Toevoeging', order: 8 }));
    createProperty(source, self, 'postCode', new uiInterface({ required: true, label: 'Postcode', validator: validatePostCode, rxValidChars: rxValidPostCodeChars, minLen: 6, maxLen: 7, order: 9 }));
    createProperty(source, self, 'city', new uiInterface({ required: true, label: 'Plaats', order: 10 }));
    ////////////////////////////////////////////////////////////////////
    createProperty(source, self, 'streetNameChange', new uiInterface({ required: true, order: 11 }));
    createProperty(source, self, 'houseNumberChange', new uiInterface({ required: true, order: 12 }));
    createProperty(source, self, 'affixChange', new uiInterface({ required: false, order: 13 }));
    createProperty(source, self, 'postCodeChange', new uiInterface({ required: true, validator: validatePostCode, rxValidChars: rxValidPostCodeChars, minLen: 6, maxLen: 7, order: 14 }));
    createProperty(source, self, 'cityChange', new uiInterface({ required: true, order: 15 }));
    createProperty(source, self, 'addressChangeDate', new uiInterfaceDate({ required: true, order: 16 }));
    ////////////////////////////////////////////////////////////////////
    createProperty(source, self, 'accountNumber', new uiInterface({ required: true, order: 17 }));
    createProperty(source, self, 'accountNumberChangeDate', new uiInterfaceDate({ required: true, order: 18 }));

    ////////////////////////////////////////////////////////////////////
    createProperty(source, self, 'partnerFirstName', new uiInterface({ required: requiredIfCivilState, label: 'Partner voorletter', order: 19 }));
    createProperty(source, self, 'partnerMiddleName', new uiInterface({ required: false, label: 'Partner tussenvoegsel', order: 20 }));
    createProperty(source, self, 'partnerLastName', new uiInterface({ required: requiredIfCivilState, label: 'Partner achternaam', order: 21 }));
    createProperty(source, self, 'partnerGender', new uiInterface({ required: requiredIfCivilState, name: 'partnerGender2', label: 'Partner geslacht', order: 22, optionA: 'male', optionB: 'female' }));
    createProperty(source, self, 'partnerBirthDate', new uiInterfaceDate({ required: requiredIfCivilState, label: 'Partner geboortedatum', order: 23 }));
    createProperty(source, self, 'partnerIncome', new uiInterface({ required: requiredIfCivilState, label: 'Partner netto inkomen per maand?', order: 24, minLen: 1, maxLen: 5, minVal: 1, maxVal: 10000, step: 1, unit: "€", placeholder: "€500", eIn: easeIn, eOut: easeOut }));
    createProperty(source, self, 'partnerIncomeSource', new uiInterface({ required: requiredIfCivilState, label: 'Partner wat is uw inkomstenbron?', order: 25, placeholder: 'Vast dienstverband' }));
    createProperty(source, self, 'partnerDateService', new uiInterfaceDate({ required: requiredIfCivilState, label: 'Partner datum in dienst', order: 26 }));

    ////////////////////////////////////////////////////////////////////
    createProperty(source, self, 'emailAddress', new uiInterface({ required: true, label: 'E-mail', order: 27, validator: validateEmail, rxValidChars: rxValidEmailChars }));
    createProperty(source, self, 'fixedPhoneNumber', new uiInterface({ required: false, label: 'Vaste telefoonnummer', validator: validatePhone, rxValidChars: rxLegalPhoneChars, minLen: 10, maxLen: 10, order: 28}));
    createProperty(source, self, 'mobilePhoneNumber', new uiInterface({ required: true, label: 'Mobiele telefoonnummer', validator: validatePhone, rxValidChars: rxLegalPhoneChars, minLen: 10, maxLen: 10, order: 29 }));
    createProperty(source, self, 'contactsChangeDate', new uiInterfaceDate({ required: true, order: 30 }));
    ////////////////////////////////////////////////////////////////////
    createProperty(source, self, 'comments', new uiInterface({ required: false, label: 'Opmerkingen', order: 31 }));

    return self;
}

function _smallForm() {
    var self = this;
    var source = smallForm();

    createProperty(source, self, 'name', new uiInterface({ required: true, label: 'Naam' }));
    createProperty(source, self, 'phoneNumber', new uiInterface({ required: true, label: 'Telefoonnummer', validator: validatePhone, rxValidChars: rxLegalPhoneChars, minLen: 10, maxLen: 10 }));
    createProperty(source, self, 'emailAddress', new uiInterface({ required: true, label: 'e-Mailadres', validator: validateEmail, rxValidChars: rxValidEmailChars }));

    return self;
}

function partnershipForm() {
    return {
        companyName: new String(),
        firstName: new String(),
        lastName: new String(),
        gender: new String(),
        phoneNumber: new String(),
        mobilePhoneNumber: new String(),
        emailAddress: new String(),
        comments: new String()
    };
}

function _partnershipForm() {
    var self = this;
    var source = partnershipForm();

    createProperty(source, self, 'companyName', new uiInterface({ required: true, label: 'Naam bedrijf', order: 0 }));
    createProperty(source, self, 'firstName', new uiInterface({ required: true, label: 'Voornaam', order: 1 }));
    createProperty(source, self, 'lastName', new uiInterface({ required: true, label: 'Achternaam' }));
    createProperty(source, self, 'gender', new uiInterface({ required: true, name: "gender", label: 'Geslacht', optionA: 'male', optionB: 'female', order: 2 }));
    createProperty(source, self, 'phoneNumber', new uiInterface({ required: true, label: 'Telefoonnummer', validator: validatePhone, rxValidChars: rxLegalPhoneChars, minLen: 10, maxLen: 10, order: 3}));
    createProperty(source, self, 'mobilePhoneNumber', new uiInterface({ required: true, label: 'Mobiele telefoonnummer', validator: validatePhone, rxValidChars: rxLegalPhoneChars, minLen: 10, maxLen: 10, order: 4}));
    createProperty(source, self, 'emailAddress', new uiInterface({ required: true, label: 'E-mail', validator: validateEmail, rxValidChars: rxValidEmailChars, order: 5 }));
    createProperty(source, self, 'comments', new uiInterface({ required: true, label: 'Opmerkingen', order: 6}));

    return self;
}

function leaseCalculatorForm() {
    return {
        desiredCar: new String(),
        monthlyPayment: new Number(),
        companyName: new String(),
        cocNumber: new Number(),
        comments: new String(),
        title: new String(),
        initials: new String(),
        prefix: new String(),
        lastName: new String(),
        birthDate: new Date(NaN),
        phoneNumber: new String(),
        email: new String(),
    };
}

function _leaseCalculatorForm() {
    var self = this;
    var source = leaseCalculatorForm();

    createProperty(source, self, 'desiredCar', new uiInterface({ name: "desiredCar", required: false, label: 'De gewenste auto', order: 0 }));
    createProperty(source, self, 'monthlyPayment', new uiInterface({ required: true, label: 'Uw gewenste maandtermijn', unit: "€", placeholder: "€7.000" }));
    createProperty(source, self, 'companyName', new uiInterface({ name: "companyName", required: true, label: 'Naam van uw bedrijf', order: 2 }));
    createProperty(source, self, 'cocNumber', new uiInterface({ name: "cocNumber", required: true, label: 'KvK nummer', order: 3 }));
    createProperty(source, self, 'comments', new uiInterface({ name: "comments", required: false, label: 'Opmerkingen', order: 4 }));
    createProperty(source, self, 'title', new uiInterface({ required: true, label: 'Annhef', name: "title", optionA: 'Mr', optionB: 'Mrs', order: 5}));
    createProperty(source, self, 'initials', new uiInterface({ name: "initials", required: true, label: 'Voorletters', order: 6}));
    createProperty(source, self, 'prefix', new uiInterface({ name: "prefix", required: true, label: 'Tussenvoegsel', order: 7}));
    createProperty(source, self, 'lastName', new uiInterface({ name: "lastName", required: true, label: 'Achternaam', order: 8}));
    createProperty(source, self, 'birthDate', new uiInterfaceDate({ name: "birthDate", required: true, label: 'geboortedag', order: 9}));
    createProperty(source, self, 'phoneNumber', new uiInterface({ name: "phoneNumber", required: true, label: 'Telefoonnummer', validator: validatePhone, rxValidChars: rxLegalPhoneChars, minLen: 10, maxLen: 10, order: 10}));
    createProperty(source, self, 'email', new uiInterface({ name: "email", required: true, label: 'E-mail', validator: validateEmail, rxValidChars: rxValidEmailChars, order: 11}));

    return self;
}

function carLeaseCalculatorForm() {
    return {
        credit_amount: new Number(),
        commission: new Number(),
        duration: new String(),
        final_term: new Number(),
    };
}

function _carLeaseCalculatorForm() {
    var self = this;
    var source = carLeaseCalculatorForm();

    createProperty(source, self, 'credit_amount', new uiInterface({ required: true, label: 'Kredietbedrag', unit: "€", placeholder: "€7.000" }));
    createProperty(source, self, 'commission', new uiInterface({ required: true, label: 'Provisiepercentage' }));
    createProperty(source, self, 'duration', new uiInterface({
        required: true, label: 'Looptijd', options: [
        'Maak uw keuze',
        '12 Maanden',
        '24 Maanden',
        '36 Maanden',
        '48 Maanden',
        '60 Maanden',
        '72 Maanden'
    ] }));
    createProperty(source, self, 'final_term', new uiInterface({ required: true, label: 'Slottermijn', unit: "€", placeholder: "€1.000" }));

    return self;
}

export { _form0, _form1, _form2, _smallForm, _partnershipForm, _carLeaseCalculatorForm, _leaseCalculatorForm };
