<template>
  <div class="formCheck">
    <div class="exp-item-title-container" @click="$emit('toggle-tab', 3)">
      <div class="exp-item-title">
        <div class="left">
          <span>3. Uw contactgegevens</span>
        </div>

        <div class="right">
          <div class="arrow"></div>
        </div>
      </div>
    </div>
    <div class="exp-item-text-container">
      <div class="exp-item-text">
        <div class="sep-20"></div>
        <div class="exp-item-body">

          <div class="row-11-a">
            <div class="col">
              <div class="txt-label">{{ formData.phoneNumber.ui.label }}</div>
            </div>
            <div class="col">
              <div class="inputs">
                <input-text v-model="formData.phoneNumber"></input-text>
              </div>
            </div>
          </div>

          <div class="row-11-a">
            <div class="col">
              <div class="txt-label">{{ formData.email.ui.label }}</div>
            </div>
            <div class="col">
              <div class="inputs">
                <input-text v-model="formData.email"></input-text>
              </div>
            </div>
          </div>

          <div class="row-11-a textarea-inputs">
            <div class="col">
              <div class="txt-label">{{ formData.comments.ui.label }}</div>
            </div>
            <div class="col">
              <div class="inputs">
                <textarea v-model="formData.comments"></textarea>
              </div>
            </div>
          </div>

          <div class="sep-20"></div>

        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@border-width: 2px;
@gridRowGap: 3em;
  .exp-item-title-container {
    cursor: pointer;

    .exp-item-title {
      height: pxToEm(95);
      margin: 0 pxToEm(20);
      display: flex;
      align-items: center;

      &.valid {
        color: getColor("greenDefault");
      }

      .left {
        height: 100%;
        flex: 2 1 auto;
        display: flex;
        align-items: center;
        font-size: pxToPercent(18);
        font-weight: 600;
      }

      .right {
        width: pxToEm(40);
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .arrow {
          position: relative;
          padding: pxToEm(5);
          transform: rotate(45deg);
          border: solid getColor("textColor");
          border-width: 0 pxToEm(2) pxToEm(2) 0;
        }
      }
    }
  }

  .exp-item-text-container {
    max-width: pxToEm(300);
    margin: pxToEm(10) auto pxToEm(50);
    padding: 0 pxToEm(20);

    .exp-item-text {
      .exp-item-body {
        display: grid;
        grid-row-gap: @gridRowGap;

        .txt-label {
          margin-bottom: pxToEm(10);
          font-size: pxToPercent(16);
          font-weight: 300;
        }

        .col-11 {
          display: grid;
          grid-template-rows: 1fr pxToEm(52);

          &.textarea-input {
            grid-template-rows: 1fr pxToEm(210);
          }

          .info-row {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .input-dropdown {
              width: 100%;
              margin-right: pxToEm(20);
            }
          }
        }

        .col-111 {
          display: grid;
          grid-template-rows: 1fr pxToEm(68) pxToEm(52);

          .input-slider {
            margin-bottom: pxToEm(16);
          }
        }

        .radio-input {
          position: relative;
        }

        textarea {
          width: 100%;
          height: pxToEm(210);
          text-align: left;
          font-size: pxToPercent(18);
          background-color: getColor("whiteColor");
          border: @border-width solid getColor("grayLtColor");
          resize: none;
        }
      }

      .loans {
        margin-bottom: pxToEm(50);

        .sub-title {
          margin-top: pxToEm(40);
          margin-bottom: pxToEm(20);
          font-size: pxToPercent(24);
        }
      }
    }
  }

  .exp-item{
    &.expanded {
      height: auto;

      .exp-item-title-container .exp-item-title .right .arrow {
        transform: rotate(-135deg);
      }

      .error {
        display: block;
      }
    }
  }

  .btn-big {
    .content {
      margin-left: pxToEm(20);
      margin-right: pxToEm(20);

      .txt {
        margin-right: pxToEm(20);
        font-size: pxToPercent(16);
        font-weight: 700;
        text-align: left;
      }

      img {
        width: pxToEm(36);
        height: pxToEm(28);
      }
    }
  }
</style>

<script>
import inputText from "@/components/common/input-text";
import inputDropdown from "@/components/common/input-dropdown";
import btnBig from "@/components/common/btn-big";

export default {
  name: "ContactDetails",
  components: {
    inputText,
    inputDropdown,
    btnBig,
  },
  props: {
    formData: Object,
    tab: Object,
  },
};
</script>