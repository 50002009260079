<template>
  <div class="area" v-if="block">
    <div
      style="
        width: 100%;
        height: 45px;
        background-color: white;
        position: absolute;
      "
    ></div>
    <div class="layout">
      <div
        class="pic"
        v-if="
          block.Image &&
          block.Image.data &&
          block.Image.data.attributes &&
          block.Image.data.attributes.url
        "
        :style="{
          'background-image':
            'url(' + strapiAssetUrl(block.Image.data.attributes.url) + ')',
        }"
      ></div>

      <div class="section">
        
        <div class="title">
          <span>{{ block.Title }}</span>
        </div>
        <div class="subtitle">
          <span>{{ block.Subtitle }}</span>
        </div>

        <calculator-form
          :commissionPercentage="block.CommissionPercentage"
          :interestPercentage="block.InterestPercentage"
          :finalTermDefaultValue="block.FinalTermDefaultValue"
          :finalTermShow="block.FinalTermShow"
          :paymentPerMonth="block.PaymentPerMonth"
          :subTitle="block.Subtitle"
          class="bg-white"
        ></calculator-form>
        
        <div class="btn-container" @click="goToRoute">
          <btn-big class="primary" :height="68">
            <div class="content">
              <div class="txt">Financial lease aanvragen</div>
              <img src="@/assets/arrow.svg" alt />
            </div>
          </btn-big>
        </div>

      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.area {
  // height: pxToEm(440);
  padding-bottom: pxToEm(45);
  padding-top: pxToEm(45);
  background-color: getColor("greyLight");

  .layout {
    .pic {
      background-size: cover;
      background-repeat: no-repeat;
      height: pxToEm(440);
      width: 100%;
      position: absolute;
      background-position-x: 14%;

      .filter {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
      }
    }

    .section {
      padding: pxToEm(50) pxToEm(25);
      background: rgba(0,0,0,0.5);

      .title {
        font-size: pxToPercent(34);
        color: getColor("whiteColor");
        text-align: left;
        font-weight: 700;
        // margin-top: pxToEm(50);
        line-height: 1em;
        width: 270px;
      }
      .subtitle {
        font-size: pxToPercent(24);
        color: getColor("whiteColor");
        text-align: left;
        font-weight: 700;
        margin-top: pxToEm(10);
        line-height: 1em;
        width: 270px;
      }

      .container{
        .bg-white{
          padding: 10px;
          background-color: #FFF;
        }
      }

      .btn-container {
        max-width: pxToEm(300);
        margin: 0 auto;
        position: relative;

        .btn-big {
          flex: 0 1 auto;

          .txt {
            font-size: pxToPercent(16);
            font-weight: 700;
            text-align: left;
            padding: 10px;
          }

          .content {
            margin-left: pxToEm(17);
            margin-right: pxToEm(17);
          }

          img {
            width: pxToEm(36);
            height: pxToEm(28);
          }
        }
      }
    }
  }
}
</style>

<script>
import headerImg from "@/assets/homepage-header.jpg";
import btnBig from "@/components/common/btn-big";
import { _carLeaseCalculatorForm } from "@/data/contactData.js";
import { goToAnchor, checkTab } from "@/JS/helpers";
import { strapiAssetUrl } from "@/JS/helpers";
import calculatorForm from "@/components/common/lease-calculator-form";

export default {
  name: "mobCarLeasingCalculator",
  props: {
    block: Object,
  },
  components: {
    btnBig,
    calculatorForm,
  },
  data() {
    return {
      headerImg,
      formSent: false,
      calculatorForm: { fields: new _carLeaseCalculatorForm(), state: undefined },
    };
  },
  methods: {
    strapiAssetUrl: function (path) {
      return strapiAssetUrl(path);
    },
    submitForm() {
      this.calculatorForm.state = checkTab(this.calculatorForm.fields);
    },
    goToRoute() {
      window.location = this.block.url;
    }
  },
};
</script>
