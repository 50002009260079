<template>
  <div class="area" v-if="block">
    <div
      style="
        width: 100%;
        height: 45px;
        background-color: white;
        position: absolute;
      "
    ></div>
    <div class="layout">
      <div
        class="pic"
        v-if="
          block.Image &&
          block.Image.data &&
          block.Image.data.attributes &&
          block.Image.data.attributes.url
        "
        :style="{
          'background-image':
            'url(' + strapiAssetUrl(block.Image.data.attributes.url) + ')',
        }"
      ></div>
      
      <div class="section">
        <div class="container">
          <div class="only-tablet logo-wrapper">
            <img src="../../../assets/logo.png" />
          </div>
          <h1 class="fisrt-txt">
            <span class="pink">{{ block.Title }}</span>
          </h1>
          <div class="title" v-if="block.Subtitle">
            <div class="txt">{{block.Subtitle}}</div>
          </div>
          <calculator-form
            :commissionPercentage="block.CommissionPercentage"
            :interestPercentage="block.InterestPercentage"
            :finalTermDefaultValue="block.FinalTermDefaultValue"
            :finalTermShow="block.FinalTermShow"
            :paymentPerMonth="block.PaymentPerMonth"
            :subTitle="block.Subtitle"
          ></calculator-form>

           <btn-big class="allGreen" :width="370" :height="92" :click="goToRoute">
            <div class="content">
              <div class="txt">Financial lease aanvragen</div>
              <img src="@/assets/arrow.svg" alt>
            </div>
          </btn-big>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .area {
    background-color: getColor('greyLight');
    height: pxToEm(824);

    .only-tablet.logo-wrapper {
      img {
        max-width: pxToEm(400);
        margin-top: pxToEm(10);

      }

      @media (min-width: 1240px) {
        display: none;
      }
    }

    .layout {
      justify-content: center;
      align-items: center;
      text-align: left;

      .pic {
        position: absolute;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        right: 0;
        left: 50%;
        margin-left: -420px;
        height: 824px;
      }

      .btn-container {
        width: 226px;
        background-color: #3a3a3a;
        z-index: 100;
        position: absolute;
        right: 0;
        padding: 40px;

        .text-cont {
          color: #fff;
          font-size: pxToPercent(18);
          margin-bottom: pxToEm(10);
        }

        .input {
          height: 62px;
          font-size: pxToPercent(14);
        }

        span {
          font-size: pxToPercent(16);
          font-weight: 300;
          color: #fff;
          text-align: center;
          margin-bottom: pxToEm(10);
        }

        .btn-big {
          position: relative;
          text-align: left;

          .txt {
            font-size: pxToPercent(14);
          }

          .content {
            margin-left: pxToEm(20);
            margin-right: pxToEm(20);
          }

          img {
            width: pxToEm(40);
            height: pxToEm(32);
          }
        }
      }

      .section {
        width: 100%;
        max-width: 1300px;

        .pink {
          color: getColor('primaryLight');
          font-weight: 800;
        }

        .container {
          background-color: getColor('greyLight');
          width: pxToEm(452);
          height: pxToEm(715);

          @media (max-width: 1240px) {
            height: pxToEm(630);
          }

          padding: pxToEm(20) pxToEm(50) 0 pxToEm(120);

          .first-txt {
            color: #3a3a3a;
            text-align: left;
            padding-top: pxToEm(15);
            font-size: pxToEm(46);
            line-height: 1.1em;
            font-weight: 800;
            margin-top: 0;
            margin-bottom: 0;

            &.first-txt {
              @media (max-width: 1240px) {
                padding-top: pxToEm(5);
              }
            }
          }
        }
      }
    }
  }
</style>

<style lang="less">
.btn-big {
  .content {
    .txt {
      font-size: pxToPercent(28);
      padding-left: 20px;
    }
    img{
      margin-right: 20px
    }
  }
}
</style>

<script>
import headerImg from "@/assets/homepage-header.jpg";
import btnBig from "@/components/common/btn-big";
import { _carLeaseCalculatorForm } from "@/data/contactData.js";
import { goToAnchor, checkTab } from "@/JS/helpers";
import { strapiAssetUrl } from "@/JS/helpers";
import calculatorForm from "@/components/common/lease-calculator-form";

export default {
  name: "deskCarLeasingCalculato",
  props: {
    block: Object,
  },
  components: {
    btnBig,
    calculatorForm,
  },
  data() {
    return {
      headerImg,
      formSent: false,
      calculatorForm: { fields: new _carLeaseCalculatorForm(), state: undefined },
    };
  },
  methods: {
    strapiAssetUrl: function (path) {
      return strapiAssetUrl(path);
    },
    submitForm() {
      this.calculatorForm.state = checkTab(this.calculatorForm.fields);
    },
    goToRoute() {
      window.location = this.block.url;
    }
  },
};
</script>
